import "primevue/resources/themes/nova-alt/theme.css"
import "primevue/resources/primevue.min.css"
import "primeicons/primeicons.css"
import "@/styles/main.scss"

import { createApp } from "vue"
import App from "./App.vue"
import "./registerServiceWorker"
import router from "./router"
import store from "./store"
import PrimeVue from "primevue/config"
import Galleria from "primevue/galleria"
import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { faEnvelope, faMobileAlt } from "@fortawesome/free-solid-svg-icons"
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons"

library.add(faMobileAlt, faEnvelope, faFacebookSquare)

createApp(App)
  .component("Galleria", Galleria)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(PrimeVue)
  .use(store)
  .use(router)
  .mount("#app")
