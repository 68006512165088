<template>
  <div class="home">
    <img width="250" alt="Hexea Studio" src="../assets/logo.png" />
    <Contact />
    <!-- <ComingSoon msg="Open for enquiries" /> -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
// import ComingSoon from "@/components/ComingSoon.vue"
import Contact from "@/components/Contact.vue"

export default defineComponent({
  name: "Home",
  components: {
    // ComingSoon,
    Contact,
  },
})
</script>
