<template>
  <div class="contact-page">
    <!-- <div class="person-info-area">
      <h4>Director</h4>
      <p>Mr. Lam</p>
    </div> -->
    <div>Contact Us</div>
    <div class="contact-area">
      <a href="tel:+601111016778">
        <font-awesome-icon :icon="['fas', 'mobile-alt']" />
        +6011-110-16778
      </a>
      <a href="mailto:lamkokhau@hotmail.com">
        <font-awesome-icon :icon="['fas', 'envelope']" />
        hexeastudio@gmail.com
      </a>
      <a href="https://www.facebook.com/hexeastudio">
        <font-awesome-icon :icon="['fab', 'square-facebook']" />
        Hexea Studio
      </a>
    </div>
    <h1 class="coming-soon-font">COMING SOON</h1>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  name: "Contact",
})
</script>

<style lang="scss">
h4 {
  margin: 0;
}

.person-info-area {
  padding: 24px 6px;

  h4,
  p {
    margin: 0;
  }
}

.contact-area {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  a {
    // display: block;
    padding: 6px 12px;
  }
}

.coming-soon-font {
  font-size: 6rem;
  font-weight: lighter;

  @media (max-width: 767px) {
  font-size: 2rem;
}
}
</style>
